import { useCallback, useEffect, useMemo, useState } from 'react';
import * as anchor from '@project-serum/anchor';
import Header from './components/Header';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import styled from 'styled-components';
import { Container, Snackbar } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Commitment,
  Connection,
  PublicKey,
  Transaction,
} from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';
import {
  awaitTransactionSignatureConfirmation,
  CANDY_MACHINE_PROGRAM,
  CandyMachineAccount,
  createAccountsForMint,
  getCandyMachineState,
  getCollectionPDA,
  mintOneToken,
  SetupState,
} from './candy-machine';
import { AlertState, formatNumber, getAtaForMint, toDate } from './utils';
import { MintCountdown } from './MintCountdown';
import { MintButton } from './MintButton';
import { GatewayProvider } from '@civic/solana-gateway-react';
import { sendTransaction } from './connection';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

const ConnectButton = styled(WalletDialogButton)`
  width: 320px;
  height: 60px;
  left: 670px;
  top: 4524px;
  background: #fff;
  border-radius: 50px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000;
  border: 0;
`;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
  network: WalletAdapterNetwork;
}

const Home = (props: HomeProps) => {
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: '',
    severity: undefined,
  });
  const [isActive, setIsActive] = useState(false);
  const [endDate, setEndDate] = useState<Date>();
  const [itemsRemaining, setItemsRemaining] = useState<number>();
  const [isWhitelistUser, setIsWhitelistUser] = useState(false);
  const [isPresale, setIsPresale] = useState(false);
  const [isValidBalance, setIsValidBalance] = useState(false);
  const [discountPrice, setDiscountPrice] = useState<anchor.BN>();
  const [needTxnSplit, setNeedTxnSplit] = useState(true);
  const [setupTxn, setSetupTxn] = useState<SetupState>();
  const [successTxn, setSucessTxn] = useState<SetupState>();
  const rpcUrl = props.rpcHost;
  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const refreshCandyMachineState = useCallback(
    async (commitment: Commitment = 'confirmed') => {
      if (!anchorWallet) {
        return;
      }

      const connection = new Connection(props.rpcHost, commitment);

      if (props.candyMachineId) {
        try {
          const cndy = await getCandyMachineState(
            anchorWallet,
            props.candyMachineId,
            connection,
          );
          console.log('candymachine', cndy.state);
          let active =
            cndy?.state.goLiveDate?.toNumber() < new Date().getTime() / 1000;
          let presale = false;

          // duplication of state to make sure we have the right values!
          let isWLUser = false;
          let userPrice = cndy.state.price;

          // whitelist mint?
          if (cndy?.state.whitelistMintSettings) {
            // is it a presale mint?
            if (
              cndy.state.whitelistMintSettings.presale &&
              (!cndy.state.goLiveDate ||
                cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
            ) {
              presale = true;
            }
            // is there a discount?
            if (cndy.state.whitelistMintSettings.discountPrice) {
              setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);
              userPrice = cndy.state.whitelistMintSettings.discountPrice;
            } else {
              setDiscountPrice(undefined);
              // when presale=false and discountPrice=null, mint is restricted
              // to whitelist users only
              if (!cndy.state.whitelistMintSettings.presale) {
                cndy.state.isWhitelistOnly = true;
              }
            }
            // retrieves the whitelist token
            const mint = new anchor.web3.PublicKey(
              cndy.state.whitelistMintSettings.mint,
            );
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];

            try {
              const balance = await connection.getTokenAccountBalance(token);
              isWLUser = parseInt(balance.value.amount) > 0;
              // only whitelist the user if the balance > 0
              setIsWhitelistUser(isWLUser);

              if (cndy.state.isWhitelistOnly) {
                active = isWLUser && (presale || active);
              }
            } catch (e) {
              setIsWhitelistUser(false);
              // no whitelist user, no mint
              if (cndy.state.isWhitelistOnly) {
                active = false;
              }
              console.log(
                'There was a problem fetching whitelist token balance',
              );
              console.log(e);
            }
          }
          userPrice = isWLUser ? userPrice : cndy.state.price;

          if (cndy?.state.tokenMint) {
            // retrieves the SPL token
            const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
            const token = (
              await getAtaForMint(mint, anchorWallet.publicKey)
            )[0];
            try {
              const balance = await connection.getTokenAccountBalance(token);

              const valid = new anchor.BN(balance.value.amount).gte(userPrice);

              // only allow user to mint if token balance >  the user if the balance > 0
              setIsValidBalance(valid);
              active = active && valid;
            } catch (e) {
              setIsValidBalance(false);
              active = false;
              // no whitelist user, no mint
              console.log('There was a problem fetching SPL token balance');
              console.log(e);
            }
          } else {
            const balance = new anchor.BN(
              await connection.getBalance(anchorWallet.publicKey),
            );
            const valid = balance.gte(userPrice);
            setIsValidBalance(valid);
            active = active && valid;
          }

          // datetime to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.date) {
            setEndDate(toDate(cndy.state.endSettings.number));
            if (
              cndy.state.endSettings.number.toNumber() <
              new Date().getTime() / 1000
            ) {
              active = false;
            }
          }
          // amount to stop the mint?
          if (cndy?.state.endSettings?.endSettingType.amount) {
            let limit = Math.min(
              cndy.state.endSettings.number.toNumber(),
              cndy.state.itemsAvailable,
            );
            if (cndy.state.itemsRedeemed < limit) {
              setItemsRemaining(limit - cndy.state.itemsRedeemed);
            } else {
              setItemsRemaining(0);
              cndy.state.isSoldOut = true;
            }
          } else {
            setItemsRemaining(cndy.state.itemsRemaining);
          }

          if (cndy.state.isSoldOut) {
            active = false;
          }

          const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
          const collectionPDAAccount = await connection.getAccountInfo(
            collectionPDA,
          );

          setIsActive((cndy.state.isActive = active));
          setIsPresale((cndy.state.isPresale = presale));
          setCandyMachine(cndy);

          const txnEstimate =
            892 +
            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
            (cndy.state.tokenMint ? 177 : 0) +
            (cndy.state.whitelistMintSettings ? 33 : 0) +
            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 145 : 0) +
            (cndy.state.gatekeeper ? 33 : 0) +
            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

          setNeedTxnSplit(txnEstimate > 1230);
        } catch (e) {
          if (e instanceof Error) {
            if (
              e.message === `Account does not exist ${props.candyMachineId}`
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state from candy machine with address: ${props.candyMachineId}, using rpc: ${props.rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
                severity: 'error',
                hideDuration: null,
              });
            } else if (
              e.message.startsWith('failed to get info about account')
            ) {
              setAlertState({
                open: true,
                message: `Couldn't fetch candy machine state with rpc: ${props.rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
                severity: 'error',
                hideDuration: null,
              });
            }
          } else {
            setAlertState({
              open: true,
              message: `${e}`,
              severity: 'error',
              hideDuration: null,
            });
          }
          console.log(e);
        }
      } else {
        setAlertState({
          open: true,
          message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
          severity: 'error',
          hideDuration: null,
        });
      }
    },
    [anchorWallet, props.candyMachineId, props.rpcHost],
  );

  const onMint = async (
    beforeTransactions: Transaction[] = [],
    afterTransactions: Transaction[] = [],
  ) => {
    try {
      setIsUserMinting(true);
      setSucessTxn(undefined);
      document.getElementById('#identity')?.click();
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        let setupMint: SetupState | undefined;
        if (needTxnSplit && setupTxn === undefined) {
          setAlertState({
            open: true,
            message: 'Please sign account setup transaction',
            severity: 'info',
          });
          setupMint = await createAccountsForMint(
            candyMachine,
            wallet.publicKey,
          );
          let status: any = { err: true };
          if (setupMint.transaction) {
            status = await awaitTransactionSignatureConfirmation(
              setupMint.transaction,
              props.txTimeout,
              props.connection,
              true,
            );
          }
          console.log('status', status)
          console.log('setupMint', setupMint)
          if (status && !status.err) {            
            setSetupTxn(setupMint);
            setAlertState({
              open: true,
              message:
                'Setup transaction succeeded! Please sign minting transaction',
              severity: 'info',
            });
          } else {
            setAlertState({
              open: true,
              message: 'Mint failed! Please try again!',
              severity: 'error',
            });
            setIsUserMinting(false);
            return;
          }
        } else {
          setAlertState({
            open: true,
            message: 'Please sign minting transaction',
            severity: 'info',
          });
        }

        let mintResult = await mintOneToken(
          candyMachine,
          wallet.publicKey,
          beforeTransactions,
          afterTransactions,
          setupMint ?? setupTxn,
        );

        let status: any = { err: true };
        let metadataStatus = null;
        if (mintResult) {
          status = await awaitTransactionSignatureConfirmation(
            mintResult.mintTxId,
            props.txTimeout,
            props.connection,
            true,
          );

          metadataStatus =
            await candyMachine.program.provider.connection.getAccountInfo(
              mintResult.metadataKey,
              'processed',
            );
          console.log('Metadata status: ', !!metadataStatus);
        }

        if (status && !status.err && metadataStatus) {
          // manual update since the refresh might not detect
          // the change immediately
          let remaining = itemsRemaining! - 1;
          setItemsRemaining(remaining);
          setIsActive((candyMachine.state.isActive = remaining > 0));
          candyMachine.state.isSoldOut = remaining === 0;
          setSucessTxn(setupMint);
          setSetupTxn(undefined);
          
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
            hideDuration: 7000,
          });
          refreshCandyMachineState('processed');
        } else if (status && !status.err) {
          setAlertState({
            open: true,
            message:
              'Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.',
            severity: 'error',
            hideDuration: 8000,
          });
          refreshCandyMachineState();
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          });
          refreshCandyMachineState();
        }
      }
    } catch (error: any) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction timeout! Please try again.';
        } else if (error.message.indexOf('0x137')) {
          console.log(error);
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          console.log(error);
          message = `SOLD OUT!`;
          window.location.reload();
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      });
      // updates the candy machine state to reflect the latest
      // information on chain
      refreshCandyMachineState();
    } finally {
      setIsUserMinting(false);
    }
  };

  const toggleMintButton = () => {
    let active = !isActive || isPresale;

    if (active) {
      if (candyMachine!.state.isWhitelistOnly && !isWhitelistUser) {
        active = false;
      }
      if (endDate && Date.now() >= endDate.getTime()) {
        active = false;
      }
    }

    if (
      isPresale &&
      candyMachine!.state.goLiveDate &&
      candyMachine!.state.goLiveDate.toNumber() <= new Date().getTime() / 1000
    ) {
      setIsPresale((candyMachine!.state.isPresale = false));
    }

    setIsActive((candyMachine!.state.isActive = active));
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    refreshCandyMachineState,
  ]);

  const [showMint, setShowMint] = useState(false);

  useEffect(() => {
    (function loop() {
      setTimeout(() => {
        refreshCandyMachineState();
        loop();
      }, 20000);
    })();
  }, [refreshCandyMachineState]);

  const displaymint = (val: boolean) => {
    if (val) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    setShowMint(val);
  };

  return (
    <Container className="App" style={{ marginTop: 50 }}>    
      <Header/>
      <div className='container'>
        <div className='intro'>

          <div className='title'>
            <img src='Flagships.png' />
          </div>
          <div className='subtitle'>
            Your VIP ticket to the biggest upcoming Projects, NFT's and giveaways.
          </div>
          <button onClick={(e) => {
            displaymint(true);
          }}>
            Start Minting Now
          </button>
        </div>
      </div>
      <div className='container'>
        <div className='about section' id="about">
          <div className='left'>
            <img src='Asset 3@4x.png' />
            <img src='about.png' />
          </div>
          <div className='right'>
            <div className='headline'>
              About Flagship NFTs
            </div>
            <div className='text'>
              Flagships are a collection of 777 randomised Spaceship themed NFTs floating along the Solana Blockchain. All holders of a Flagship will receive whitelist spots on all future Nvirometa projects. They will also be entered into all upcoming giveaways of every Nvirometa NFT roadmap.
              <br /><br />
              Nvirometa are commited to making a positive impact with the work they do, and one of the ways they are achieving this is by donating 20-50% of all net profits to partnering charities, this helps provide investors and holders of Nvirometa NFT's the opportunity to play a part in the change they are making.

            </div>
          </div>
        </div>
        <div className='minting section' id="minting">
          <div className='left'>
            <img src='Asset 11@4x 1.png' />
            <img src='minting.png' />
          </div>
          <div className='right'>
            <div className='headline'>
              Minting
            </div>
            <div  className='text-center mb-4'>
              <img src='image 22.png' />
            </div>

            <div className='text'>
              Click Connect Wallet and select Phantom. A wallet extension appears, asking for a password. If you are registered for exchange, you agree to connect to the site.
              <br /><br />
              You will see a Mint button if the connection is successful. Please select the number of NFTs you want to obtain. Click to open the wallet confirmation window. Finally, click Approve Transaction and start benefiting.
              <br /><br />
              This process usually takes just some seconds, and you won’t necessarily get a notification at the end.
            </div>
          </div>
        </div>
        <div className='gallery section' id="gallery">
          <div className='left'>
            <img src='Asset 5@4x 1.png' />
            <img src='gallery.png' />
          </div>
          <div className='right'>
            <div className='headline'>
              Gallery
            </div>

            <div className='text'>
              <div className='row'>
                <div className='col-lg-4'>
                  <img src='Mask group.png' />
                </div>
                <div className='col-lg-4 center'>
                  <img src='Mask group1.png' />
                </div>
                <div className='col-lg-4'>
                  <img src='Mask group2.png' />
                </div>
                <div className='col-lg-4'>
                  <img src='Mask group3.png' />
                </div>
                <div className='col-lg-4 center'>
                  <img src='Mask group4.png' />
                </div>
                <div className='col-lg-4'>
                  <img src='Mask group5.png' />
                </div>
                <div className='col-lg-4'>
                  <img src='Mask group6.png' />
                </div>
                <div className='col-lg-4 center'>
                  <img src='Mask group7.png' />
                </div>
                <div className='col-lg-4'>
                  <img src='Mask group8.png' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flagship section'>
          <div className='left'>
            <img src='Asset 6@4x 1.png' />
            <img src='flagship.png' />
          </div>
          <div className='right'>
            <div className='headline'>
              Flagship Roadmap
            </div>
            <div className='text'>
              <div className="container">
                <div className="history-list-column">
                  <div className="history-list-column-item">
                    <div className="history-list-column-item-bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                        <circle stroke="none" cx="16" cy="16" r="10"></circle>
                      </svg>
                    </div>
                    <div className="history-list-column-item-content">
                      <div className="history-list-column-item-title">20th April 2022</div>
                      <div className="history-list-column-item-desc">Discord Launch
                      </div>
                    </div>
                  </div>
                  <div className="history-list-column-item">
                    <div className="history-list-column-item-bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                        <circle stroke="none" cx="16" cy="16" r="10"></circle>
                      </svg>
                    </div>
                    <div className="history-list-column-item-content">
                      <div className="history-list-column-item-title">10th June 2022</div>
                      <div className="history-list-column-item-desc">White list Mint
                      </div>
                    </div>
                  </div>
                  <div className="history-list-column-item">
                    <div className="history-list-column-item-bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                        <circle stroke="none" cx="16" cy="16" r="10"></circle>
                      </svg>
                    </div>
                    <div className="history-list-column-item-content">
                      <div className="history-list-column-item-title">21st May 2022
                      </div>
                      <div className="history-list-column-item-desc">Public Mint
                      </div>
                    </div>
                  </div>
                  <div className="history-list-column-item">
                    <div className="history-list-column-item-bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                        <circle stroke="none" cx="16" cy="16" r="10"></circle>
                      </svg>
                    </div>
                    <div className="history-list-column-item-content">
                      <div className="history-list-column-item-title">14th June 2022
                      </div>
                      <div className="history-list-column-item-desc">Art Reveal
                      </div>
                    </div>
                  </div>
                  <div className="history-list-column-item">
                    <div className="history-list-column-item-bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                        <circle stroke="none" cx="16" cy="16" r="10"></circle>
                      </svg>
                    </div>
                    <div className="history-list-column-item-content">
                      <div className="history-list-column-item-title">July 2022
                      </div>
                      <div className="history-list-column-item-desc">Mystery Giveaway
                      </div>
                    </div>
                  </div>
                  <div className="history-list-column-item">
                    <div className="history-list-column-item-bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                        <circle stroke="none" cx="16" cy="16" r="10"></circle>
                      </svg>
                    </div>
                    <div className="history-list-column-item-content">
                      <div className="history-list-column-item-title">August 2022
                      </div>
                      <div className="history-list-column-item-desc">Private group chat invite giveaway</div>
                    </div>
                  </div>
                  <div className="history-list-column-item">
                    <div className="history-list-column-item-bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                        <circle stroke="none" cx="16" cy="16" r="10"></circle>
                      </svg>
                    </div>
                    <div className="history-list-column-item-content">
                      <div className="history-list-column-item-title">August 2022
                      </div>
                      <div className="history-list-column-item-desc">Flagship holders Airdropped first WL ticket</div>
                    </div>
                  </div>
                  <div className="history-list-column-item">
                    <div className="history-list-column-item-bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                        <circle stroke="none" cx="16" cy="16" r="10"></circle>
                      </svg>
                    </div>
                    <div className="history-list-column-item-content">
                      <div className="history-list-column-item-title">September 2022
                      </div>
                      <div className="history-list-column-item-desc">First Partnered Drop (+ additional utility)</div>
                    </div>
                  </div>
                  <div className="history-list-column-item">
                    <div className="history-list-column-item-bullet">
                      <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                        <circle stroke="none" cx="16" cy="16" r="10"></circle>
                      </svg>
                    </div>
                    <div className="history-list-column-item-content">
                      <div className="history-list-column-item-title">November 2022
                      </div>
                      <div className="history-list-column-item-desc">Release of Road map phase 2</div>
                    </div>
                  </div>
                </div>

                {/* <!-- DESKTOP --> */}


                <div className="history-list-row">
                  <div className="history-list-item-content-left">
                    <div className="history-list-item-title">20th April 2022</div>
                    <div className="history-list-item-desc">Discord Launch</div>
                  </div>



                  <div className="history-list-row-item-line-left"></div>
                  <div className="history-list-row-item-bullet-left">
                    {/* <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg> */}
                  </div>
                  <div className="history-list-row-item-bullet-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <circle id="Oval" cx="11" cy="11" r="10.5" fill="#fff" fill-opacity="1" stroke="#f84018" />
                    </svg>
                  </div>
                </div>


                <div className="history-list-row">
                  <div className="history-list-row-item-bullet-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <circle id="Oval" cx="11" cy="11" r="10.5" fill="#fff" fill-opacity="1" stroke="#f84018" />
                    </svg>
                  </div>


                  <div className="history-list-row-item-bullet-right">
                    {/* <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg> */}
                  </div>
                  <div className="history-list-row-item-line-right"></div>


                  <div className="history-list-item-content-right">
                    <div className="history-list-item-title">10th June 2022</div>
                    <div className="history-list-item-desc">White list Mint</div>
                  </div>
                </div>


                <div className="history-list-row">
                  <div className="history-list-item-content-left">
                    <div className="history-list-item-title">21st May 2022</div>
                    <div className="history-list-item-desc">Public Mint</div>
                  </div>
                  <div className="history-list-row-item-line-left"></div>

                  <div className="history-list-row-item-bullet-left">
                    {/* <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg> */}
                  </div>


                  <div className="history-list-row-item-bullet-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <circle id="Oval" cx="11" cy="11" r="10.5" fill="#fff" fill-opacity="1" stroke="#f84018" />
                    </svg>
                  </div>
                </div>


                <div className="history-list-row">
                  <div className="history-list-row-item-bullet-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <circle id="Oval" cx="11" cy="11" r="10.5" fill="#fff" fill-opacity="1" stroke="#f84018" />
                    </svg>
                  </div>


                  <div className="history-list-row-item-bullet-right">
                    {/* <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg> */}
                  </div>
                  <div className="history-list-row-item-line-right"></div>


                  <div className="history-list-item-content-right">
                    <div className="history-list-item-title">14th June 2022</div>
                    <div className="history-list-item-desc">Art Reveal</div>
                  </div>
                </div>


                <div className="history-list-row">
                  <div className="history-list-item-content-left">
                    <div className="history-list-item-content">
                      <div className="history-list-item-title">July 2022</div>
                      <div className="history-list-item-desc">Mystery Giveaway</div>
                    </div>
                  </div>
                  <div className="history-list-row-item-line-left"></div>

                  <div className="history-list-row-item-bullet-left">
                    {/* <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg> */}
                  </div>


                  <div className="history-list-row-item-bullet-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <circle id="Oval" cx="11" cy="11" r="10.5" fill="#fff" fill-opacity="1" stroke="#f84018" />
                    </svg>
                  </div>
                </div>


                <div className="history-list-row">
                  <div className="history-list-row-item-bullet-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <circle id="Oval" cx="11" cy="11" r="10.5" fill="#fff" fill-opacity="1" stroke="#f84018" />
                    </svg>
                  </div>


                  <div className="history-list-row-item-bullet-right">
                    {/* <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg> */}
                  </div>
                  <div className="history-list-row-item-line-right"></div>


                  <div className="history-list-item-content-right">
                    <div className="history-list-item-title">August 2022</div>
                    <div className="history-list-item-desc">Private group chat invite giveaway</div>
                  </div>
                </div>


                <div className="history-list-row">
                  <div className="history-list-item-content-left">
                    <div className="history-list-item-title">August 2022</div>
                    <div className="history-list-item-desc">Flagship holders Airdropped first WL ticket</div>
                  </div>
                  <div className="history-list-row-item-line-left"></div>

                  <div className="history-list-row-item-bullet-left">
                    {/* <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg> */}
                  </div>


                  <div className="history-list-row-item-bullet-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <circle id="Oval" cx="11" cy="11" r="10.5" fill="#fff" fill-opacity="1" stroke="#f84018" />
                    </svg>
                  </div>
                </div>


                <div className="history-list-row">
                  <div className="history-list-row-item-bullet-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <circle id="Oval" cx="11" cy="11" r="10.5" fill="#fff" fill-opacity="1" stroke="#f84018" />
                    </svg>
                  </div>
                  <div className="history-list-row-item-bullet-right">
                    {/* <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg> */}
                  </div>
                  <div className="history-list-row-item-line-right"></div>



                  <div className="history-list-item-content-right">
                    <div className="history-list-item-title">September 2022</div>
                    <div className="history-list-item-desc">First Partnered Drop (+ additional utility)</div>
                  </div>
                </div>


                <div className="history-list-row">
                  <div className="history-list-item-content-left">
                    <div className="history-list-item-title">November 2022</div>
                    <div className="history-list-item-desc">Release of Road map phase 2</div>
                  </div>


                  <div className="history-list-row-item-line-left"></div>

                  <div className="history-list-row-item-bullet-left">
                    <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                      <circle stroke="none" cx="16" cy="16" r="10"></circle>
                    </svg>
                  </div>

                  <div className="history-list-row-item-bullet-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                      <circle id="Oval" cx="11" cy="11" r="10.5" fill="#fff" fill-opacity="1" stroke="#f84018" />
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className='team section'>
          <div className='left'>
            <img src='Asset 4@4x 1.png' />
            <img src='team.png' />
          </div>
          <div className='right'>
            <div className='headline'>
              Team
            </div>
            <div className='text'>
              <div className='row'>
                <div className='col-lg-4 text-center'>
                  <img src='cavan.png' />
                  <div>Cavan B</div>
                  <div>CEO, Creative lead</div>
                </div>
                <div className='col-lg-4 text-center'>
                  <img src='aaron.png' />
                  <div>Aaron C</div>
                  <div>CFO</div>

                </div>
                <div className='col-lg-4 text-center'>
                  <img src='lee.png' />
                  <div>Lee B</div>
                  <div>CMO,  Project Manager</div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='faq section'>
          <div className='left'>
            <img src='Asset 10@4x 1.png' />
            <img src='faq.png' />
          </div>
          <div className='right'>
            <div className='headline'>
              FAQ
            </div>
            <div className='text'>
              <ul className='row pl-0'>
                <li>
                  <div className='question'>How do I get a flagship NFT?</div>
                  <div className='answer'>Jump on our discord as an early supporter and receive a whitelist spot for presale launch or wait for public mint.</div>
                </li>
                <li>
                  <div className='question'>When is the official launch?</div>
                  <div className='answer'>Pre-sale mint is on 10 June 2022 and the public mint is on 11 June 2022.</div>
                </li>
                <li>
                  <div className='question'>What will the price be?</div>
                  <div className='answer'>Presale - 6 SOL <br />
                    Public sale - 6 SOL
                  </div>
                </li>
                <li>
                  <div className='question'>How do I get a Flagship whitelist spot?</div>
                  <div className='answer'>The first 100 people to invite 10 friends on our Discord will get a whitelist spot, with the remaining whitelist spots being allocated through give aways on our discord and social media.</div>
                </li>
                <li>
                  <div className='question'>How can I use my NFT?</div>
                  <div className='answer'>All holders of Flagship NFT’s will be regularly airdropped whitelist tokens and giveaway entries to all future Nvirometa projects. </div>
                </li>
                <li>
                  <div className='question'>What Blockchain is Flagship on?</div>
                  <div className='answer'>All Nvirometa Projects exist on the Solana Blockchain.</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='community section'>
          <div className='left'>
            <img src='Asset 8@4x 1.png' />
          </div>
          <div className='right'>
            <div className='headline'>
              Nvirometa Community
            </div>
          </div>
        </div>
        <div className='footer'>
          <div className='socials'>
            <a href='https://www.instagram.com/nvirometa/' target='_blank'>
              <img src='./insta.png' />
            </a>
            <a href='https://twitter.com/nvirometa' target='_blank'>
              <img src='./twitter.png' />
            </a>
            <a href='https://discord.gg/c6uYr3e2Zn' target='_blank'>
              <img src='./discord.png' />
            </a>
          </div>
          <a className='by' target="_blank" href="https://tokenminds.co/nft-development">NFT Development by Token Minds</a>
        </div>
      </div>      
      <div className={`mintBox${showMint ? " show" : ""}`} onClick={(e) => {
        displaymint(false);
      }}>
        <div className="mintBoxParent" onClick={proxy => proxy.stopPropagation()}>
          <div className="headline">
            <img src="/Mint Your Flagship NFT.png" className="" />
          </div>
          <div className="imgParent">
            <img src="/emojione-monotone_question-mark.png" className="" />
          </div>
          {successTxn?.transaction && <div className='row'>
            <a target="_blank" href={`https://solscan.io/tx/${successTxn?.transaction}?cluster=devnet`}>Click here to check your mint tx!</a>
          </div>}
          <div className="bottom d-flex flex-column flex-lg-row mintbutton-div">
            
          {/* {wallet.connected && <div className="count">
              <button className="minus">-</button>
              <input value={1} />
              <button className="add">+</button>
            </div>} */}
            {!wallet.connected && <ConnectButton>Connect Wallet</ConnectButton>}
            {wallet.connected &&               <MintContainer>
                {candyMachine?.state.isActive &&
                candyMachine?.state.gatekeeper &&
                wallet.publicKey &&
                wallet.signTransaction ? (
                  <GatewayProvider
                    wallet={{
                      publicKey:
                        wallet.publicKey ||
                        new PublicKey(CANDY_MACHINE_PROGRAM),
                      //@ts-ignore
                      signTransaction: wallet.signTransaction,
                    }}
                    gatekeeperNetwork={
                      candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                    }
                    clusterUrl={
                      props.network === WalletAdapterNetwork.Devnet
                        ? 'https://api.devnet.solana.com'
                        : rpcUrl
                    }
                    handleTransaction={async (transaction: Transaction) => {
                      setIsUserMinting(true);
                      const userMustSign = transaction.signatures.find(sig =>
                        sig.publicKey.equals(wallet.publicKey!),
                      );
                      if (userMustSign) {
                        setAlertState({
                          open: true,
                          message: 'Please sign one-time Civic Pass issuance',
                          severity: 'info',
                        });
                        try {
                          transaction = await wallet.signTransaction!(
                            transaction,
                          );
                        } catch (e) {
                          setAlertState({
                            open: true,
                            message: 'User cancelled signing',
                            severity: 'error',
                          });
                          // setTimeout(() => window.location.reload(), 2000);
                          setIsUserMinting(false);
                          throw e;
                        }
                      } else {
                        setAlertState({
                          open: true,
                          message: 'Refreshing Civic Pass',
                          severity: 'info',
                        });
                      }
                      try {
                        await sendTransaction(
                          props.connection,
                          wallet,
                          transaction,
                          [],
                          true,
                          'confirmed',
                        );
                        setAlertState({
                          open: true,
                          message: 'Please sign minting',
                          severity: 'info',
                        });
                      } catch (e) {
                        setAlertState({
                          open: true,
                          message:
                            'Solana dropped the transaction, please try again',
                          severity: 'warning',
                        });
                        console.error(e);
                        // setTimeout(() => window.location.reload(), 2000);
                        setIsUserMinting(false);
                        throw e;
                      }
                      await onMint();
                    }}
                    broadcastTransaction={false}
                    options={{ autoShowModal: false }}
                  >
                    <MintButton
                      candyMachine={candyMachine}
                      isMinting={isUserMinting}
                      setIsMinting={val => setIsUserMinting(val)}
                      onMint={onMint}
                      isActive={
                        isActive ||
                        (isPresale && isWhitelistUser && isValidBalance)
                      }                      
                    />
                  </GatewayProvider>
                ) : (
                  <MintButton
                    candyMachine={candyMachine}
                    isMinting={isUserMinting}
                    setIsMinting={val => setIsUserMinting(val)}
                    onMint={onMint}
                    isActive={
                      isActive ||
                      (isPresale && isWhitelistUser && isValidBalance)
                    }                    
                  />
                )}
              </MintContainer>}
            {/* <button className="connectWallet">Connect Wallet</button> */}
          </div>
          <div className="price">            
            {wallet.connected && candyMachine && isWhitelistUser && 
                        <span> Price: {formatNumber.asNumber(
                          discountPrice
                        )} SOL</span>
            }
            {wallet.connected && candyMachine && !isWhitelistUser &&
                        <span> Price: {formatNumber.asNumber(
                            candyMachine?.state.price,
                          )} SOL</span>}            
          </div>
        </div>
      </div>              
      <Snackbar
        open={alertState.open}
        autoHideDuration={
          alertState.hideDuration === undefined ? 6000 : alertState.hideDuration
        }
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const getCountdownDate = (
  candyMachine: CandyMachineAccount,
): Date | undefined => {
  if (
    candyMachine.state.isActive &&
    candyMachine.state.endSettings?.endSettingType.date
  ) {
    return toDate(candyMachine.state.endSettings.number);
  }

  return toDate(
    candyMachine.state.goLiveDate
      ? candyMachine.state.goLiveDate
      : candyMachine.state.isPresale
      ? new anchor.BN(new Date().getTime() / 1000)
      : undefined,
  );
};

export default Home;
