import '../styles/Header.css';
const Header = () => {
    return (
        <div className='header'>
            <img src='/Logo_5 1.png' className='logo' />
            <div className='left-links'>
                <a href='/'>Minting Whitelist</a>
                <a href='/#minting'>Minting NFT</a>
                <a href='/#about'>About NFTs</a>
                <a href='/#gallery'>Gallery</a>
            </div>
            <a href='https://discord.gg/c6uYr3e2Zn' target="_blank"> <img src='/discord.png' className='logo' /></a>
        </div>
    )
}

export default Header;